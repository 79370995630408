class Query {
    private _selector: string;
    private _base: Element | Document;
    
    constructor(selector: string, base: Element | Document = document) {
        this._base = base;
        this._selector = selector;
    }

    first<T extends IQueryElement>(func?: (arg1: T) => void): T | null {
        let obj = this._base.querySelector(this._selector);
        
        if(obj === null)
            return null;
        
        return this.get(obj, func);
    }
    all<T extends IQueryElement, TResult>(func: (arg1: T) => TResult) {
        let objs = this._base.querySelectorAll(this._selector);

        let toReturn = [];
        for(let x = 0; x < objs.length; x++) {
            toReturn.push(func(QueryElement.get<T>(objs[x]) as T));
        }
        return toReturn;
    }
    last<T extends IQueryElement>(func?: (arg1: T) => void): T | null {
        let obj = this._base.querySelectorAll(this._selector);

        if(obj.length === 0)
            return null;
        
        return this.get(obj[obj.length -1], func);
    }
    create<T extends IQueryElement, TElement extends Element>(c: (arg1: TElement) => T | null) {
        let obj = this._base.querySelector(this._selector);
        return obj && c(obj as TElement);
    }
    createAll<T extends IQueryElement, TElement extends Element>(c: (arg1: TElement) => T | null) {
        let objs = this._base.querySelectorAll(this._selector);

        let toReturn = [];
        for(let x = 0; x < objs.length; x++) {
            let element = c(objs[x] as TElement);
            element && toReturn.push(element);
        }

        return toReturn;
    }

    private get<T extends IQueryElement>(obj: Element, func?: (arg1: T) => void) {
        if(func === undefined)
            return QueryElement.get(obj) as T;
        
        func(QueryElement.get<T>(obj) as T);
        return null;
    }
}