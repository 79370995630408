interface IQueryElement {
    get(): Element | null;
    getInstance(): string | null;

    on<TEvent extends Event>(val: string, func: (e: TEvent) => any): (e: TEvent) => any;
    trigger(val: string, bubble: boolean): this;
    off<TEvent extends Event>(val: string, func: ((e: TEvent) => any) | null): void;

    setHtml(html: string): this;
    getHtml(): string | null;
    getText(): string | null;
    setText(text: string): this;
    getOuterHtml(): string | null;

    getAttr(attr: string): string | null;
    setAttr(attr: string, content: string): this;
    getVal(): string | undefined;
    setVal(val: string | null) : void;
    prepend(obj: IQueryElement): this;
    append(obj: IQueryElement): this;
    insertBefore(obj: IQueryElement, objBefore: IQueryElement): this;
    replace(objOld: IQueryElement, objNew: IQueryElement): this;
    appendTo(obj: IQueryElement): this;
    prependTo(obj: IQueryElement): this;
    remove(): Promise<void>;
    isRemoved(): boolean;

    addClass(className: string): this;
    containsClass(className: string): boolean;
    removeClass(className: string): this;
    toggleClass(className: string): this;
    style(): CSSStyleDeclaration | undefined;
    isVisible(): boolean;

    query(selector: string): Query;
    parent<T extends IQueryElement>(): T | null;
    children<T extends IQueryElement>(): T[];
    next<T extends IQueryElement>() : T | null;
    prev<T extends IQueryElement>(): T | null;
    nextAll<T extends IQueryElement>(func: (item: T) => boolean | void): this;
    prevAll<T extends IQueryElement>(func: (item: T) => boolean | void): this;
    parentAll<T extends IQueryElement>(func: (item: T) => boolean | void): this;
    firstChild<T extends IQueryElement>(): T | null;
    lastChild<T extends IQueryElement>(): T | null;
    clear(): this;

    focus(): this;
    select(): this;
    position(): ClientRect | null;
}

class QueryElement<T extends Element>
    implements IQueryElement {
    
    private _obj: T | null;
    private _events: { [event: string]: ((e: Event) => any)[] } = {};

    constructor(obj: T, registerInstance = true) {
        this._obj = obj as T;

        // Register only if registerInstance is true, and isn't already registered
        if(registerInstance && !obj.getAttribute("data-instance"))
            this.setAttr("data-instance", QueryCollection.add(this))
    }
    
    static QueryElement(elem: string, registerInstance = false) {
         return new QueryElement(document.createElement(elem), registerInstance);
    }

    static get<T extends IQueryElement>(elem: Element): T {
        let instance = elem.getAttribute("data-instance");
        if(instance === null) {
            // @ts-ignore
            return new QueryElement(elem, false);
        }

        return QueryCollection.get<T>(instance);
    }

    get() {
        return this._obj;
    }
    getInstance() {
        return this.getAttr("data-instance");
    }
    getVal() {
        if(this._obj instanceof HTMLInputElement
             || this._obj instanceof HTMLTextAreaElement)
            return this._obj.value;
        
        return undefined;
    }
    setVal(val: string | null) {
        let value = val || "";
        if(this._obj instanceof HTMLInputElement ) {
            this.setAttr("value", value);
            this._obj.value = value;
        } else if (this._obj instanceof HTMLTextAreaElement) {
            this._obj.innerHTML = value;
            this._obj.value = value;
        }
        
        return this;
    }


    // DOM Manipulations
    prepend(obj: IQueryElement) {
        let toInsert = obj.get();

        this._obj && 
            toInsert && 
            this._obj.insertBefore(toInsert, this._obj.firstChild);
        
        return this;
    }
    replace(objOld: IQueryElement, objNew: IQueryElement) {
        let currElement = objOld.get();
        let replaceWith = objNew.get();

        replaceWith 
            && currElement 
            && this._obj 
            && this._obj.replaceChild(replaceWith, currElement);

        return this;
    }
    append(obj: IQueryElement) {
        let toInsert = obj.get();

        this._obj && 
            toInsert && 
            this._obj.appendChild(toInsert);
        
        return this;
    }
    appendBefore(objBefore: IQueryElement) {
        let parent = objBefore.parent();
        if (parent == null)
            return this;
        
        this._obj &&
            parent.insertBefore(this, objBefore);

    }
    insertBefore(obj: IQueryElement, objBefore: IQueryElement) {
        let toInsert = obj.get();

        this._obj && 
            toInsert && 
            this._obj.insertBefore(toInsert, objBefore.get());
        
        return this;
    }
    appendTo(obj: IQueryElement) {
        obj.append(this);
        return this;
    }
    prependTo(obj: IQueryElement) {
        obj.prepend(this);
        return this;
    }
    async remove(removeInner = true) {
        if(this._obj === null)
            return;

        // Remove all data instances
        if(removeInner)
            await Promise.all(this.query("[data-instance]")
                .all<QueryElement<HTMLElement>, Promise<void>>(a => a.remove(false)));
        
        // And ofcourse this instance
        let instance = this.getInstance();
        if(instance !== null)
            QueryCollection.delete(instance);
        
        // Remove events
        for(let x in this._events) {
            this.off(x, null);
        }

        // Remove obj from DOM tree
        this._obj.remove();

        // Delete it all
        this._obj = null;
        this._events = {};
    }
    isRemoved() {
        return this._obj === null;
    }

    // Events
    on<TEvent extends Event>(val: string, func: (e: TEvent) => any) {
        if(this._events[val] === undefined)
            this._events[val] = [];

        // TODO: Bug in TS 2.6.1 <(ev: Event) => any> should be removed here
        if(this._obj !== null)
            this._obj.addEventListener(
                val, 
                <(ev: Event) => any>func, 
                false //{passive: true}{passive: true}
            );

        // Keep track of it
        this._events[val].push(<(ev: Event) => any>func);

        return func;
    }
    trigger(event: string, bubble: boolean, data?: any) {
        console.log(`Event: ${this.constructor.name}: ${event} - data: ${data}`);
        if(this._obj === null)
            return this;
        
        switch (event) {
            default:
                this._obj.dispatchEvent(new CustomEvent(event, {detail: data, bubbles:bubble}));
                break;
            case "click":
                this._obj.dispatchEvent(new MouseEvent(event, {bubbles: bubble}));
                break;
        }
        return this;
    }
    off<TEvent extends Event>(val: string, func: ((e: TEvent) => any) | null) {
        if(this._obj === null)
            return this;
        
        if(this._events[val] === undefined)
            return this;
        
        if(func === null) {
            // if func === null, remove all events of this type
            for(let event of this._events[val]) {
                this._obj.removeEventListener(
                    val, 
                    event, 
                    false //{passive: true}
                );
            }

            delete this._events[val];
        } else {
            // Else only remove this event
            let index = this._events[val].indexOf(<(ev: Event) => any>func);
            let toRemove = this._events[val].splice(index, 1);
            this._obj.removeEventListener(val, toRemove[0], false);
        }
    }
    setHtml(html: string) {
        this._obj && (this._obj.innerHTML = html);
        return this;
    }
    getHtml() {
        return this._obj && this._obj.innerHTML;
    }
    getText() {
        if (this._obj instanceof HTMLElement)
            return this._obj && this._obj.innerText;
        else
            return null;
    }
    setText(text: string) {
        if (this._obj && this._obj instanceof HTMLElement)
            this._obj.innerText = text;
        return this;
    }
    getOuterHtml() {
        return this._obj && this._obj.outerHTML;
    }
    isVisible() {
        if (this._obj !== null && this._obj instanceof HTMLElement)
            return this._obj.offsetParent !== null;

        return false;
    }
    // Attribute changes
    setAttr(attr: string, content: string) {
        this._obj && this._obj.setAttribute(attr, content);
        return this;
    }
    getAttr(attr: string) {
        return this._obj && this._obj.getAttribute(attr);
    }



    // Class Manipulations
    addClass(className: string) {
        this._obj && this._obj.classList.add(className);
        return this;
    }
    containsClass(className: string) {
        return this._obj 
            && this._obj.classList.contains(className) 
            || false;
    }
    removeClass(className: string) {
        this._obj && this._obj.classList.remove(className);
        return this;
    }
    toggleClass(className: string) {
        this._obj && this._obj.classList.toggle(className);
        return this;
    }

    style() {
        if(this._obj instanceof HTMLElement)
            return this._obj.style;
        
        return undefined;
    }

    // DOM Traversal
    query(selector: string) {
        return new Query(selector, this._obj || undefined);
    }
    parent<T extends IQueryElement>() {
        let parent = this._obj && this._obj.parentElement;
        if(parent === null)
            return null;

        return QueryElement.get<T>(parent);
    }
    children<T extends IQueryElement>() : T[] {
        if(this._obj === null)
            return [];
        
        let childs = [];
        for (let child of this._obj.children) {
            childs.push(QueryElement.get<T>(child));
        }
        return childs;
    }
    next<T extends IQueryElement>() {
        if(this._obj === null)
            return null;

        let next = this._obj.nextElementSibling;
        if(next === null)
            return null;

        return QueryElement.get<T>(next);
    }
    prev<T extends IQueryElement>() {
        if(this._obj === null)
            return null;

        let prev = this._obj.previousElementSibling;
        if(prev === null)
            return null;

        return QueryElement.get<T>(prev);
    }
    parentAll<T extends IQueryElement>(func: (item: T) => boolean | void) {
        if(this._obj === null)
            return this;

        loop<T>(this._obj, a => a.parentElement, func);
        return this;
    }
    nextAll<T extends IQueryElement>(func: (item: T) => boolean | void) {
        if(this._obj === null)
            return this;

        loop<T>(this._obj, a => a.nextElementSibling, func);
        return this;
    }
    prevAll<T extends IQueryElement>(func: (item: T) => boolean | void) {
        if(this._obj === null)
            return this;

        loop<T>(this._obj, a => a.previousElementSibling, func);
        return this;
    }
    firstChild<T extends IQueryElement>() {
        if(this._obj === null)
            return null;

        if(this._obj.firstElementChild === null)
            return null;
        
        return QueryElement.get<T>(this._obj.firstElementChild);
    }
    lastChild<T extends IQueryElement>() {
        if(this._obj === null)
            return null;

        if(this._obj.lastElementChild === null)
            return null;
        
        return QueryElement.get<T>(this._obj.lastElementChild);
    }
    clear() {
        if(this._obj === null)
            return this;
        
        for(let child of this._obj.children) {
            QueryElement.get(child).remove();
        }
        return this;
    }

    // Fast access
    focus() {
        if(this._obj instanceof HTMLElement)
            this._obj.focus();
            
        return this;
    }
    select() {
        if(this._obj instanceof HTMLInputElement || this._obj instanceof HTMLTextAreaElement) {
            this._obj.select();
        }
        return this;
    }
    position() {
        if(this._obj === null)
            return null;

        return this._obj.getBoundingClientRect();
    }
}

function loop<T extends IQueryElement>(obj: Element, 
                property: (item: Element) => Element | null, 
                func: (item: T) => boolean | void) {
    let elem: Element | null;
    while((elem = property(obj)) !== null) {
        let r = func(QueryElement.get(elem));

        if(r === false)
            return;
        
        obj = elem;
    }
}