class QueryCollection {
    static test: any = {};
    static index: number = 0;

    static add(elem: IQueryElement) {
        var index = (QueryCollection.index++).toString();
        QueryCollection.test[index] = elem;
        return index;
    }
    static get<T extends IQueryElement>(index: string) {
        return <T>QueryCollection.test[index];
    }
    static delete(index: string | null) {
        if(index === null)
            return;
        
        delete QueryCollection.test[index];
    }
}
