interface TouchList {
    [Symbol.iterator](): IterableIterator<Touch>;
}
interface FileList {
    [Symbol.iterator](): IterableIterator<File>;
}
interface HTMLCollection {
    [Symbol.iterator](): IterableIterator<Element>;
}
function debounce<T extends unknown[], U>(
    callback: (...args: T) => PromiseLike<U> | U,
    wait: number
) {
    let timer: number;

    return (...args: T): Promise<U> => {
        clearTimeout(timer);
        return new Promise((resolve) => {
            timer = setTimeout(() => resolve(callback(...args)), wait);
        });
    };
}
function throttle<T extends unknown[], U>(
    callback: (...args: T) => PromiseLike<U> | U,
    wait: number
) {
    let run: boolean = true;
    let timer: number;

    return (...args: T): Promise<U> => {
        clearTimeout(timer);

        if (run == true) {
            run = false;
            setTimeout(() => run = true, wait);
            return new Promise((resolve) => resolve(callback(...args)));
        }

        return new Promise((resolve) => {
            timer = setTimeout(() => resolve(callback(...args)), wait);
        });
    };
}
HTMLCollection.prototype[Symbol.iterator] = Array.prototype[Symbol.iterator];
FileList.prototype[Symbol.iterator] = Array.prototype[Symbol.iterator];
TouchList.prototype[Symbol.iterator] = Array.prototype[Symbol.iterator];