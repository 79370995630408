class QueryJSON {
    static deserializeCast<T>(c: {new(): T; }, json: string) : T | null {
        try {
            let obj = JSON.parse(json);
            return QueryJSON.cast(c, obj);
        } catch(e) {

        }

        return null;
    }
    static deserialize<T>(json: string): T | null {
        try {
            let obj = JSON.parse(json);
            return <T>obj;
        } catch(e) {
            
        }

        return null;
    }
    static serialize(obj: any) {
        return JSON.stringify(obj);
    }

    static cast<T>(c: {new(): T; }, obj: any) {
        let ts = new c();

        obj.__proto__ = ts;
        return <T>obj;
    }

    static toCamelCase(string: string) {
        return string.replace(/(?:^|\.)([A-Z]+)/g, function (value: string, toCamel: string) {
            if (toCamel.length == 1)
                return value.toLowerCase();

            return value.slice(0, value.length - 1).toLowerCase() + value.slice(value.length - 1, value.length);
        })
    }
}