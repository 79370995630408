class QueryEventedModel<T> {
    public model: T;
    public event: Event;

    constructor(model: T, event: Event)
    {
        this.model = model;
        this.event = event;
    }
}

interface IQueryEvent<T> {
    on(handler: { (data: T): void }): { (data: T): void };
    off(handler: { (data: T): void }): { (data: T): void };
}
interface IQueryTriggerEvent<T> {
    trigger(data: T): void;
}

class QueryEvent<T>
    implements IQueryEvent<T>, IQueryTriggerEvent<T> {
    
    private handlers: { (data: T): void; }[] = [];

    public on(handler: { (data: T): void }) {
        this.handlers.push(handler);
        return handler;
    }

    public off(handler: { (data: T): void }) {
        this.handlers = this.handlers.filter(h => h !== handler);
        return handler;
    }

    public trigger(data: T) {
        for(let x of this.handlers.slice(0)) {
            x(data);
        }
    }

    public exposeTrigger(): IQueryTriggerEvent<T> {
        return this;
    }
    public expose() : IQueryEvent<T> {
        return this;
    }
}

interface IQueryEventAsync<T> {
    on(handler: { (data: T): Promise<void> }): { (data: T): Promise<void> };
    off(handler: { (data: T): Promise<void> }): { (data: T): Promise<void> };
}
interface IQueryTriggerEventAsync<T> {
    trigger(data: T): Promise<void>;
}

class QueryEventAsync<T>
    implements IQueryEventAsync<T>, IQueryTriggerEventAsync<T> {
    
    private handlers: { (data: T): Promise<void>; }[] = [];

    public on(handler: { (data: T): Promise<void> }) {
        this.handlers.push(handler);
        return handler;
    }

    public off(handler: { (data: T): Promise<void> }) {
        this.handlers = this.handlers.filter(h => h !== handler);
        return handler;
    }

    public async trigger(data: T) {
        for(let x of this.handlers.slice(0)) {
            await x(data);
        }
    }

    public exposeTrigger(): IQueryTriggerEventAsync<T> {
        return this;
    }
    public expose() : IQueryEventAsync<T> {
        return this;
    }
}