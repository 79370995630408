type QueryRequestMethods = 'post' | 'put' | 'get' | 'delete' | 'head';
type QueryRequestContentTypes = 'json' | 'plain' | 'form' | 'file';
type QueryRequestCredentials = 'same-origin' | 'include';

interface IQueryRequestOptions {
    contentType?: QueryRequestContentTypes,
    method?: QueryRequestMethods,
    data?: string | Blob | FormData,
    credentials?: QueryRequestCredentials,
    useCache?: boolean,
    headers?: Record<string, string>
}

class QueryRequest
{
    private readonly _url: string;
    private readonly _options: IQueryRequestOptions;

    constructor(url: string, options?: IQueryRequestOptions) {
        this._url = url;
        this._options = options || <IQueryRequestOptions>{};
    }
    async request() {
        let headers = new Headers();

        // Append content type
        let contentType = this.getContentType();
        if(contentType)
            headers.append('Content-Type', contentType);

        // Append extra headers
        if (this._options.headers != null) {
            for(var key in this._options.headers) {
                headers.append(key, this._options.headers[key]);
            }
        }
        
        let request: RequestInit = {
            body: this._options.data,
            credentials: this._options.credentials ?? "same-origin",
            headers: headers,
            method: this.getRequestMethod()
        };

        if (this._options.useCache != true) {
            request.cache = "no-cache"
        }
        
        return await fetch(this._url, request);
    }

    static blobToBase64(blob: Blob) {
        return new Promise<string>((resolve, reject) => {
            let reader = new FileReader();
            reader.readAsDataURL(blob);
            reader.onloadend = () => {
                resolve(reader.result as string);
            }
            reader.onerror = () => {
                reject();
            }
        });

    }

    private getRequestMethod() {
        let method: QueryRequestMethods;

        if(this._options.method !== undefined) {
            method = this._options.method;
        } else {
            switch(this._options.contentType) {
                default:
                    method = 'get';
                    break;
                case 'form':
                    method = 'post';
                    break;
                case 'json':
                    method = 'put';
                    break;
                case 'file':
                    method = 'post';
                    break;
            }
        }

        return method;
    }

    private getContentType() {
        switch(this._options.contentType) {
            case 'plain':
                return 'text/plain';
            case 'json':
                return 'application/json; charset=utf-8';
            case 'form':
                return null; // Must be autoset by browser
        }
    }
}